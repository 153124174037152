import { Routes } from '@angular/router';

export const routes: Routes = [
	{
		path: 'event',
		loadComponent: () => import('./event/event.component').then(m => m.EventComponent),
	},
	{
		path: '404',
		loadComponent: () => import('./not-found/not-found.component').then(m => m.NotFoundComponent),
	},
	{
		path: ':token',
		loadComponent: () => import('./access/access.component').then(m => m.AccessComponent),
	},
	{
		path: '**',
		loadComponent: () => import('./not-found/not-found.component').then(m => m.NotFoundComponent),
	},
];
